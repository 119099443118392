var isRTL = $("html").attr("dir") == "rtl" ? true : false,
  winWidth = $(window).width(),
  winHeight = $(window).height(),
  topPos = 0,
  bottomPos = 0;

$(function () {
  browserDetect();
  marquee();
  setUpScrollAnim();
  initLeadershipSlider();
});
var videoId = $(".inject-video").attr("desktop-video-id");

if (winWidth < 768) {
  videoId = $(".inject-video").attr("mobile-video-id");
}

function setUpScrollAnim() {
  // if js-scroll-trigger-a1 exists
  if ($(".js-scroll-trigger-a1").length) {
    gsap.set(
      ".js-scroll-trigger-a1 .container__inner-wrapper .auction-scroll-area",
      {
        x: auctionScrollAreaX(0),
      }
    );
    gsap.ticker.add(scrollTriggerA1Update);
  }
}

function scrollTriggerA1Update() {
  var winHeight = window.innerHeight;
  var targetItem = $(".js-scroll-trigger-a1 .auction-scroll-area");
  var targetItemHeight = targetItem.outerHeight();
  var scrollHeight = parseInt(
    document.querySelector("#luxy").style.transform.split(",")[1]
  );
  scrollHeight = Math.abs(scrollHeight) + winHeight;
  if (topPos == 0) {
    topPos = targetItem.offset().top;
  }

  // calculate percentInView as scrolHeight - topPos / winHeight
  var percentInView = scrollHeight - topPos;
  if (percentInView > 0) {
    percentInView = percentInView / (winHeight + targetItemHeight);
    gsap.to(
      ".js-scroll-trigger-a1 .container__inner-wrapper .auction-scroll-area",
      {
        x: auctionScrollAreaX(percentInView, targetItem),
        overwrite: true,
        duration: 0,
      }
    );
  }
}

function auctionScrollAreaX(percentInView, targetItem) {
  /*
    our initial value should be 100vw - container offset (should be in pixels)
    last value should be component width - container width
  */
  var x = '-100vw';
  if (percentInView > 0 && percentInView <= 1) {
    var targetItemWidth = 0;
    // calculate targetItemWidth Inner childs total width
    targetItem.children().each(function () {
      targetItemWidth += $(this).outerWidth() + parseInt($(this).css("margin-right"))  + parseInt($(this).css("margin-left"));
    });

    // our initial value should be 100vw - container offset (should be in pixels)
    var containerOffset = $('#luxy').offset().left;
    var initalValue = winWidth - containerOffset;
    
    // last value should be component width - container width
    var containerWidth = $('#luxy').width();
    var finalValue = targetItemWidth + containerWidth;

    if (document.querySelector("html").getAttribute("dir") == "rtl") {
      x = -((initalValue) - (finalValue * percentInView)) + 'px';
    } else {
      // x to be negative 100% when percentInView is 0 & positive 100% when percentInView is 1
      x = ((initalValue) - (finalValue * percentInView)) + 'px';
    }
  } else if (percentInView > 1) {
    x = '-100vw';
  }
  return `${(x)}`;
}

function domOnLoad() {
  document.querySelector("body").classList.add("loaded");
  setVideoTag();
  resumeVideo();
  skipVideo();
  staticBanner();
  if (winWidth > 992) {
    locomotiveInit();
  }
  drag();
  autoStopVideo();
}

$(window).on("load", function () {
  // Do after Page ready
  domOnLoad();
});

function browserDetect() {
  navigator.sayswho = (function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join("").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  })();
  $("body").addClass(navigator.sayswho);
}

function setVideoTag() {
  var scriptTag1 = $("<script />", {
    src: "https://fast.wistia.com/embed/medias/" + videoId + ".jsonp",
    async: true,
  });
  var scriptTag2 = $("<script />", {
    src: "https://fast.wistia.com/assets/external/E-v1.js",
    async: true,
  });
  var divTag = $("<div />", {
    class: "wistia_embed wistia_async_" + videoId,
    style: "height:100%;width:100%",
  });
  $(".inject-video").append(scriptTag1, scriptTag2, divTag);

  window._wq = window._wq || [];
  _wq.push({
    id: videoId,
    onReady: function (video) {
      document
        .querySelector(".js-toggle-mute")
        .addEventListener("click", function () {
          document
            .querySelector(".c-sound-toggle .mute")
            .classList.remove("is-active");
          document
            .querySelector(".c-sound-toggle .unMute")
            .classList.add("is-active");
          Wistia.api(videoId).mute();
        });

      document
        .querySelector(".js-toggle-unmute")
        .addEventListener("click", function () {
          document
            .querySelector(".c-sound-toggle .unMute")
            .classList.remove("is-active");
          document
            .querySelector(".c-sound-toggle .mute")
            .classList.add("is-active");
          Wistia.api(videoId).unmute();
        });
    },
    controlsVisibleOnLoad: false,
  });
}

function skipVideo() {
  $("body").on("click", ".js-skip-video, #hov", function (e) {
    e.preventDefault();
    if (winWidth > 767) {
      luxy.init();
    }
    progressBar();
    $(".js-skip-video, .c-sound-toggle").fadeOut();
    $(".js-video-stop").addClass("show");
    $("body, html").removeClass("video-playing");
    var videoId = $(".inject-video").attr("desktop-video-id");
    document.querySelector(".js-toggle-mute").click();
    $("#main-video").attr("data-mouseTxt", "");
    $(".c-mouseTracker .mt-text").text("");
    $(".c-mouseTracker").fadeOut();
    if (winWidth > 767) {
    }
    $(".banner-content").show();
    $(".videoOverlayNew").addClass("show");
    $(".js-scroll-down").addClass("show");
    $(".inject-video").removeClass("show");
    Wistia.api(videoId).pause();
  });
}

function staticBanner() {
  $("body,html").removeClass(".video-playing");
  if (winWidth > 767) {
    luxy.init();
  }
  progressBar();
  $(".js-video-stop").addClass("show");
  $("body,html").removeClass("video-playing");
  var videoId = $(".inject-video").attr("desktop-video-id");
  document.querySelector(".js-toggle-mute").click();
  //   $("#main-video").attr("data-mouseTxt", "Replay");
  //   $(".c-mouseTracker .mt-text").text("Replay");
  if (winWidth > 767) {
  }
  $(".videoOverlayNew").addClass("show");
  $(".js-scroll-down").addClass("show");
}

function resumeVideo() {
  $(document).on("click", ".js-resume-video", function (e) {
    e.preventDefault();

    $("html, body").animate({ scrollTop: 0 }, 500);
    $(".js-video-stop").removeClass("show");
    $(".js-video-play, .js-skip-video, .c-sound-toggle").fadeIn();
    $("body, html").addClass("video-playing");
    $(".videoOverlayNew").removeClass("show");
    $("#main-video").attr(
      "data-mouseTxt",
      $(".js-mainbanner").attr("data-closeTxt")
    );
    $(".c-mouseTracker").fadeIn();
    $(".c-mouseTracker .mt-text").text(
      $(".js-mainbanner").attr("data-closeTxt")
    );

    $(".js-scroll-down").removeClass("show");
    $(".banner-content").hide();
    $(".inject-video").addClass("show");
    document.querySelector(".js-toggle-unmute").click();
    Wistia.api(videoId).time(0).play();
  });
}

function autoStopVideo() {
  const Obs = new IntersectionObserver(function (entry, observer) {
    if (entry[0]?.intersectionRatio === 0) {
      // This would auto check if value is 0 which is equivalent to false
      const target = entry[0]?.target;
      const videoId = $(target).attr("data-videoid");
      Wistia.api(videoId).pause();
    }
  });
  const ELs_inViewport = document.querySelectorAll(".js-autostop-video");
  ELs_inViewport.forEach((EL) => {
    Obs.observe(EL, {});
  });
}

function locomotiveInit() {
  const inViewport = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        entry.target.classList.add("in-view");
      }
      // entry.target.classList.toggle("in-view", entry.isIntersecting && entry.intersectionRatio > 0);
    });
  };

  const Obs = new IntersectionObserver(inViewport);
  const obsOptions = {}; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

  // Attach observer to every [data-inviewport] element:
  const ELs_inViewport = document.querySelectorAll(".square");
  ELs_inViewport.forEach((EL) => {
    Obs.observe(EL, obsOptions);
  });

  var windowWidth;
  var bgSection = document.querySelectorAll(".bg-section");
  var vhElement = document.querySelectorAll(".vh-element");

  function resize() {
    if (windowWidth != window.innerWidth) {
      windowWidth = window.innerWidth;
      for (var i = bgSection.length - 1; i >= 0; i--) {
        bgSection[i].style.height = window.innerHeight + 800 + "px";
      }
    }
    isResize = false;
  }

  resize();

  window.addEventListener("resize", function () {
    if (!isResize) {
      setTimeout(function () {
        isResize = true;
        resize();
      }, 200);
    }
  });
}

function drag() {
  const slider = document.querySelector(".items");
  let isDown = false;
  let startX;
  let scrollLeft;

  slider.addEventListener("mousedown", (e) => {
    isDown = true;
    slider.classList.add("active");
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener("mouseleave", () => {
    isDown = false;
    slider.classList.remove("active");
  });
  slider.addEventListener("mouseup", () => {
    isDown = false;
    slider.classList.remove("active");
  });
  slider.addEventListener("mousemove", (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX; //scroll-slow
    slider.scrollLeft = scrollLeft - walk;
  });
}

function marquee() {
  $(".marquee").marquee({
    allowCss3Support: true,
    startVisible: true,
  });
}

function initLeadershipSlider() {
  var swiper2 = new Swiper(".js-leadership-content-slider", {
    slidesPerView: 1,
    speed: 600,
    effect: "fade",
    autoHeight: true,
    navigation: {
      nextEl: ".js-swiper-right",
      prevEl: ".js-swiper-left",
    },
    allowTouchMove: false,
  });
  var swiper = new Swiper(".js-leadership-image-slider", {
    slidesPerView: 1,
    speed: 600,
    allowTouchMove: false,
    navigation: {
      nextEl: ".js-swiper-right",
      prevEl: ".js-swiper-left",
    },
    thumbs: {
      swiper: swiper2,
    },
  });
}

function progressBar() {
  var body = document.body,
    html = document.documentElement;

  var height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  var scroll = $(window).scrollTop();
  $(window).on("scroll", function () {
    var scroll = $(window).scrollTop();
    var percent = (scroll / height) * 100;
    $(".progressBar span").css(
      "width",
      percent >= 89 ? 100 + "%" : percent + "%"
    );
  });
}

// get mouse position on viewport
var mouse = { x: 0, y: 0 };
document.addEventListener("mousemove", function (e) {
  if (winWidth > 767) {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
    mouseText();
  }
});

function mouseText() {
  // track .c-mouseTracker element to mouse position
  var mouseTracker = document.querySelector(".c-mouseTracker");
  mouseTracker.style.transform =
    "translate(" + mouse.x + "px, " + mouse.y + "px)";
}

$(function () {
  // remove .c-loading-overlay
  $(".c-loading-overlay").addClass("is-loaded");

  // is mouse is hovered over any element with attribute data-mouseTxt then use data-mouseTxt value to populate .c-mouseTracker .mt-text
  var mouseTracker = document.querySelector(".c-mouseTracker");
  var mouseTxt = document.querySelectorAll("[data-mouseTxt]");
  mouseTxt.forEach(function (el) {
    el.addEventListener("mouseenter", function (e) {
      var mouseTxt = e.target.getAttribute("data-mouseTxt");
      mouseTracker.querySelector(".mt-text").innerHTML = mouseTxt;
      var mouseTxtStyle = e.target.getAttribute("data-mouseTxtStyle");
      // add style as a class on .c-mouseTracker
      mouseTxtStyle && mouseTracker.classList.add(mouseTxtStyle);
    });
    el.addEventListener("mouseleave", function (e) {
      mouseTracker.querySelector(".mt-text").innerHTML = "";
      // remove any class that starts with style-
      var classes = mouseTracker.classList;
      for (var i = 0; i < classes.length; i++) {
        if (classes[i].indexOf("style-") > -1) {
          mouseTracker.classList.remove(classes[i]);
        }
      }
    });
  });
});

$(".js-scroll-down").click(function () {
  $("html, body").animate(
    {
      scrollTop: $(".js-mainbanner + *").offset().top,
    },
    2000
  );
});

// set isRTL based on HTML dir attribute
var isRTL = $("html").attr("dir") == "rtl" ? true : false;

$('.js-img-carousel').slick({
  rtl: isRTL,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  fade: true,
  cssEase: 'linear',
  autoplay: true,
  autoplaySpeed: 2000,
});

var set_dot_timeout;
function set_dot() {
  if(!$('.js-video-carousel .video-item')){
    return
  }
  set_dot_timeout = setTimeout(function () {
    $('.js-video-carousel .video-item').each(function (index, el) {
      var imgSrc = $(el).find('.w-css-reset > img').attr('src');
      if (!imgSrc) {
        set_dot();
        return;
      }
      $('.js-video-carousel .slick-dots li').eq(index).find('img').attr('src', imgSrc);
    });
    clearTimeout(set_dot_timeout);
  }, 2000);
}

var video_carousel = $('.js-video-carousel').slick({
  rtl: isRTL,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  infinite: true,
  speed: 500,
  fade: true,
  cssEase: 'linear',
  dots: true,
  // custom dots
  customPaging: function (slider, i) {
    return '<span class="dot"><img src=""></span>'
  }
});

video_carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {

  // Pause the current video
  const currentVideoId = slick.$slides[currentSlide].querySelector('.wistia_embed').classList[1].split('_').pop();
  const currentVideo = Wistia.api(currentVideoId);
  if (currentVideo) {
    currentVideo.pause();
  }

  // Play the next video
  const nextVideoId = slick.$slides[nextSlide].querySelector('.wistia_embed').classList[1].split('_').pop();
  const nextVideo = Wistia.api(nextVideoId);
  if (nextVideo) {
    nextVideo.play();
  }
});

// on slider init
set_dot();